<template>
  <v-card>
    <v-card-title class="text-h6">
      {{ $t("labels.zalo_black_list") }}
      <v-spacer></v-spacer>
      <v-btn color="purple" outlined @click="showExcelDialog" class="mr-2">
        {{ $t("labels.excel") }}
      </v-btn>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        fixed-header
        height="calc(100vh - 305px)"
        class="table-padding-2-no-top"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th class="text-center" style="width: 125px">
                <InputFilter
                  :label="$t('labels.phone_number')"
                  :placeholder="$t('labels.phone_number')"
                  name="phone"
                  sort-name="phone"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center">
                <InputFilter
                  :label="$t('labels.note')"
                  :placeholder="$t('labels.note')"
                  name="note"
                  sort-name="note"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="width: 125px">
                <SelectFilter
                  :options="statusOptions"
                  :label="$t('labels.status')"
                  :placeholder="$t('labels.status')"
                  name="status"
                  sort-name="status"
                  has-sort
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th class="text-center" style="width: 100px">
                <v-btn small color="primary" @click="addItem">{{
                  $t("labels.add")
                }}</v-btn>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in items"
              :key="item.id"
              class="text-center"
            >
              <td class="">
                <span v-if="!item.editing">{{ item.phone }}</span>
                <v-text-field
                  v-else
                  v-model="item.phone"
                  :disabled="!item.editing"
                  class="c-input-small input-disabled-bold"
                  :placeholder="$t('labels.phone_number')"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td>
                <span v-if="!item.editing">{{ item.note }}</span>
                <v-text-field
                  v-else
                  v-model="item.note"
                  :disabled="!item.editing"
                  class="c-input-small input-disabled-bold"
                  :placeholder="$t('labels.note')"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-text-field>
              </td>
              <td class="text-center">
                <span v-if="!item.editing">
                  {{ item.status ? "Hoạt động" : "Dừng hoạt động" }}
                </span>
                <div v-else class="d-flex align-center justify-center">
                  <v-checkbox
                    v-model="item.status"
                    :disabled="!item.editing"
                    class="mt-0 ml-1"
                    :value="1"
                    hide-details
                  ></v-checkbox>
                </div>
              </td>
              <td class="text-center">
                <template v-if="!item.editing">
                  <v-btn
                    class="mr-1"
                    x-small
                    color="warning"
                    @click="toggleEditing(item, index, true)"
                  >
                    {{ $t("labels.edit") }}
                  </v-btn>
                </template>

                <template v-else>
                  <v-btn
                    class="mr-1"
                    x-small
                    color="success"
                    @click="saveItem(item)"
                    >{{ $t("labels.save") }}</v-btn
                  >
                  <v-btn
                    x-small
                    color="error"
                    @click="toggleEditing(item, index, false)"
                  >
                    {{ $t("labels.cancel") }}
                  </v-btn>
                </template>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div v-if="totalPage > 1">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </div>
    </v-card-text>

    <v-dialog v-model="uploadExcelDialog" persistent max-width="480px">
      <ZaloBlackListExcel
        v-if="uploadExcelDialog"
        :item="item"
        @cancel="hideExcelDialog"
        @refreshData="getList"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { ACTIVE_OPTIONS } from "@/libs/const";

export default {
  name: "ZaloBlackList",
  components: {
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    ZaloBlackListExcel: () => import("@/components/account/ZaloBlackListExcel"),
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isLoading: false,
    filters: {},
    totalPage: 0,
    items: [],
    updatingItem: {},
    statusOptions: [...ACTIVE_OPTIONS],
    uploadExcelDialog: false,
  }),
  watch: {
    filters: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    cancel() {
      this.$emit("cancel");
    },
    addItem() {
      const item = {
        editing: true,
        id: null,
        status: 1,
        phone: null,
        note: null,
      };
      this.items.unshift(item);
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    showExcelDialog() {
      this.uploadExcelDialog = true;
    },
    hideExcelDialog() {
      this.uploadExcelDialog = false;
    },
    async saveItem(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-zalo-zns-black-list", {
          items: [
            {
              ...item,
            },
          ],
          id_customer_zalo_zns_config: this.item.id,
        });
        this.isLoading = false;
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.getList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async getList() {
      try {
        const { data } = await httpClient.post("/get-zalo-zns-black-list", {
          ...this.filters,
          id_customer_zalo_zns_config: this.item.id,
        });
        this.totalPage = data.totalPage;
        this.items = [...data.rows];
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
